import React from 'react'
import styled from 'styled-components'
import colors from '../../../constants/colors'

const TestimonialWrap = styled.div`
  color: ${colors.WHITE};
  overflow: auto;
  background-color: ${colors.BLUE};
  margin-top: 0;
  a:link,
  a:visited,
  a:hover {
    color: ${colors.WHITE};
  }
`
const TestimonialContent = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding: 8rem 2rem;
  max-width: 64rem;
  text-align: center;
`
const Quote = styled.div`
  font-size: 3.5rem;
  margin: 0 0 3rem 0;
`
const Cite = styled.div`
  font-size: 2.4rem;
  margin: 0;
`
const Location = styled.div`
  font-size: 2rem;
  margin: 0;
`

// End Image CTA Components'
const Testimonial = props => (
  <TestimonialWrap>
    <TestimonialContent padded short center>
      <Quote>&ldquo;{props.quote}&rdquo;</Quote>
      <Cite>&mdash; {props.cite}</Cite>
      <Location>{props.location}</Location>
    </TestimonialContent>
  </TestimonialWrap>
)

export default Testimonial
