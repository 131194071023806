import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import {
  graphql, navigate,
} from 'gatsby'
import 'react-notifications/lib/notifications.css'
import {
  NotificationContainer, NotificationManager,
} from 'react-notifications'
import axios from 'axios'
import Recaptcha from 'react-recaptcha'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageHero from '../../components/Blocks/PageHero'

import {
  Page,
  Main,
  Content,
  ColorWrap,
  SubTitle,
  IntroParagraph,
  FormWrap,
  FormRow,
  FormTitle,
  FormRowSplit,
  FormButtonRow,
  FormRecapchaRow,
  FormCheckboxWrapper,
} from '../../components/StyledComponents'
import Testimonial from '../../components/Blocks/Testimonial'
import IconCustom from '../../components/Base/Icon/Custom'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-request-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-request.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default class GetStarted extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      organization: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      referredBy: '',
      referredByDescription: '',
      referredByPrompt: '',
      isVerified: false,
      isPilot: false,
    }
  }

  handleDropdownInputChange = event => {
    if (event.target.value === 'Other') {
      this.setState({
        referredByPrompt: 'Please specify...',
        referredByDescription: '',
      })
      document.getElementById('label-referredByDescription').style.display = 'block'
    }
    else if (event.target.value === 'Personal Recommendation') {
      this.setState({
        referredByPrompt: 'Please let us know who referred you so we can thank them...',
        referredByDescription: '',
      })
      document.getElementById('label-referredByDescription').style.display = 'block'
    }
    else {
      this.setState({
        referredByPrompt: '',
      })
      document.getElementById('label-referredByDescription').style.display = 'none'
    }

    const {
      target,
    } = event
    const {
      value,
    } = target
    const {
      name,
    } = target
    this.setState({
      [name]: value,
    })
  }

  handleInputChange = event => {
    const {
      target,
    } = event
    const {
      value,
      checked,
    } = target
    const {
      name,
    } = target
    this.setState({
      [name]: name === 'isPilot' ? checked : value,
    })
  }

  recaptchaLoaded = () => {}

  validate = () => {
    const formElements = Object.keys(this.state)
    formElements.splice(11, 3)

    let count = 0
    formElements.forEach(item => {
      if (item !== 'referredBy' && item !== 'referredByDescription' && item !== 'isPilot' && document.getElementById(item).value === '') {
        document.getElementById(`label-${item}`).style.color = 'red'
        document.getElementById(`label-${item}`).style.border = 'thin solid red'
        count += 1
      }
    })

    if (count > 0) {
      alert('All fields required. Please correct highlighted items.')
      return false
    }

    if (!this.state.isVerified) {
      alert('Please verify that you are human.')
      return false
    }

    return true
  }

  verifyCallback= response => {
    if (response) {
      this.setState({
        isVerified: true,
      })
    }
  }

  resetForm = () => {
    const formElements = Object.keys(this.state)
    formElements.splice(11, 3)

    formElements.forEach(item => {
      if (document.getElementById(item).value === '') {
        document.getElementById(`label-${item}`).style.color = 'black'
        document.getElementById(`label-${item}`).style.border = 'none'
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (this.validate()) {
      const data = {
        key: '4131cb16-db72-4510-a9ea-5acd24e8236c',
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        organization: this.state.organization,
        street: this.state.street,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        isPilot: this.state.isPilot,
      }

      if (this.state.referredBy) data.referredBy = this.state.referredBy

      if (this.state.referredBy === 'Personal Recommendation' || this.state.referredBy === 'Other') {
        data.referredByDescription = this.state.referredByDescription || 'not given'
      }
      axios.post(`${process.env.SERVERLESS_API_URL}/new-account-request`, JSON.stringify(data))
        .then(() => {
          navigate('/thank-you')
        })
        .catch(() => {
          NotificationManager.error('Something went wrong.', 'Oops', 3000)
        })
    }
  }

  render() {
    const sources = [
      this.props.data.mobileImage.childImageSharp.fluid,
      {
        ...this.props.data.desktopImage.childImageSharp.fluid,
        media: '(min-width: 800px)',
      },
    ]
    return (
      <Layout>
        <SEO
          title='Get Started'
          description='Our team member will come out to meet you in person and demonstrate the system. '
        />
        <Page>
          <NotificationContainer />
          <Header />
          <Main>
            <PageHero
              background={sources}
              title='Get Started'
              position='bgr-top-right'
            />
            <ColorWrap Color='gray' pull>
              <Content padded short center>
                <ScrollAnimation animateIn='fadeInUp' animateOnce>
                  <IconCustom theme='handshake' />
                  <SubTitle>Hi! Nice to meet you.</SubTitle>
                  <IntroParagraph>
                    We need a few details to get you started.
                    Our team will review your request and reach out to you with next steps.
                  </IntroParagraph>
                </ScrollAnimation>
              </Content>
              <FormWrap
                style={{
                  marginBottom: '60px',
                }}
              >
                <form onSubmit={this.handleSubmit}>
                  <FormTitle>Tell us a little about yourself.</FormTitle>
                  <FormRowSplit>
                    <label
                      htmlFor='firstName'
                      id='label-firstName'
                    >
                      First Name
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                    <label
                      htmlFor='lastName'
                      id='label-lastName'
                    >
                      Last Name
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                    <label
                      htmlFor='email'
                      id='label-email'
                    >
                      Email
                      <input
                        id='email'
                        name='email'
                        type='email'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                    <label
                      htmlFor='phone'
                      id='label-phone'
                    >
                      Mobile Number
                      <input
                        id='phone'
                        name='phone'
                        type='phone'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                  </FormRowSplit>
                  <FormTitle>Tell us about your medical organization.</FormTitle>
                  <FormRow>
                    <label
                      htmlFor='organization'
                      id='label-organization'
                    >
                      Medical Organization Name
                      <input
                        id='organization'
                        name='organization'
                        type='text'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                  </FormRow>
                  <FormRowSplit>
                    <label
                      htmlFor='street'
                      id='label-street'
                    >
                      Street
                      <input
                        id='street'
                        name='street'
                        type='text'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                    <label
                      htmlFor='city'
                      id='label-city'
                    >
                      City
                      <input
                        id='city'
                        name='city'
                        type='text'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                    <label
                      htmlFor='state'
                      id='label-state'
                    >
                      State
                      <input
                        id='state'
                        name='state'
                        type='text'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                    <label
                      htmlFor='zip'
                      id='label-zip'
                    >
                      Zip
                      <input
                        id='zip'
                        name='zip'
                        type='text'
                        onChange={this.handleInputChange}
                        onClick={this.resetForm}
                      />
                    </label>
                  </FormRowSplit>
                  <FormCheckboxWrapper>
                    <input id="is-pilot" name="isPilot" type="checkbox" onChange={this.handleInputChange} />
                    <label htmlFor="is-pilot">
                      I am applying for the ChartMedic Pilot and will provide product feedback in exchange for waived enrollment fees.
                    </label>
                  </FormCheckboxWrapper>
                  <FormRow>
                    <label
                      htmlFor='referredBy'
                      id='label-referredBy'
                    >
                      How did you hear about us?
                      <select
                        id='referredBy'
                        name='referredBy'
                        defaultValue=''
                        onChange={this.handleDropdownInputChange}
                      >
                        <option value='' disabled hidden>
                          Choose One
                        </option>
                        <option value='Advertising'>
                          Advertising
                        </option>
                        <option value='Internet Search'>
                          Internet Search
                        </option>
                        <option value='Social Media'>
                          Social Media
                        </option>
                        <option value='Personal Recommendation'>
                          Personal Recommendation
                        </option>
                        <option value='Other'>
                          Other
                        </option>
                      </select>
                    </label>
                  </FormRow>
                  <FormRow>
                    <label
                      htmlFor='referredByDescription'
                      id='label-referredByDescription'
                    >
                      { this.state.referredByPrompt }
                      <input
                        id='referredByDescription'
                        name='referredByDescription'
                        defaultValue=''
                        type='text'
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </FormRow>
                  <FormRecapchaRow style={{
                    width: 'fit-content',
                  }}
                  >
                    <Recaptcha
                      sitekey='6LcN_PoUAAAAAPMe-NEg4uKdlrrPJZ7qfXr8H-8s'
                      render='explicit'
                      onloadCallback={this.recaptchaLoaded}
                      verifyCallback={this.verifyCallback}
                    />
                  </FormRecapchaRow>
                  <FormButtonRow>
                    <input
                      type='submit'
                      id='btn-submit'
                      onFocus={() => {
                        document.getElementById('btn-submit').style.border = '3px solid grey'
                      }}
                      onBlur={() => {
                        document.getElementById('btn-submit').style.border = 'none'
                      }}
                      value='Send Message'
                    />
                  </FormButtonRow>
                </form>
              </FormWrap>
            </ColorWrap>
            <Testimonial
              quote='ChartMedic is Awesome. It makes my day so much better.'
              cite='Medical Doctor'
              location='Seattle, WA'
            />
          </Main>
        </Page>
        <Footer hiddenPilotCta={true} />
      </Layout>
    )
  }
}
